@import "font_awesome_5_15_2/fontawesome.scss";
@import "font_awesome_5_15_2/brands.scss";
@import "font_awesome_5_15_2/light.scss";
@import "font_awesome_5_15_2/regular.scss";
@import "font_awesome_5_15_2/solid.scss";

@import "customizebootstrap";
@import "bootstrap_4_5_3/bootstrap";

/*------------------------------------ Elements & Customized Bootstrap Classes ----------------------------------*/
html,
body {
   margin:0;
   padding:0;
   height:100%;
}

p{
    max-width: 1200px;
}

.container-fluid{
    @include media-breakpoint-up(md){
        padding-right: (($grid-gutter-width * 2) / 2);
        padding-left: (($grid-gutter-width * 2) / 2);
    }
    @include media-breakpoint-up(lg){
        padding-right: (($grid-gutter-width * 3) / 2);
        padding-left: (($grid-gutter-width * 3) / 2);
    }
    @include media-breakpoint-up(xl){
        padding-right: (($grid-gutter-width * 4) / 2);
        padding-left: (($grid-gutter-width * 4) / 2);
    }
}

.navbar-brand {
    img{
        height: 30px;
    }
}

.display-1{
    font-size: $display1-size * 0.5;

    @include media-breakpoint-up(md){
        font-size: $display1-size * 0.7;
    }
    @include media-breakpoint-up(lg){
        font-size: $display1-size * 0.9;
    }
    @include media-breakpoint-up(xl){
        font-size: $display1-size;
    }
}

.display-2{
    font-size: $display2-size * 0.6;

    @include media-breakpoint-up(md){
        font-size: $display2-size * 0.7.5;
    }
    @include media-breakpoint-up(lg){
        font-size: $display2-size * 0.9;
    }
    @include media-breakpoint-up(xl){
        font-size: $display2-size;
    }
}

.display-3{

    font-size: $display3-size * 0.7;

    @include media-breakpoint-up(md){
        font-size: $display3-size * 0.8;
    }
    @include media-breakpoint-up(lg){
        font-size: $display3-size * 0.9;
    }
    @include media-breakpoint-up(xl){
        font-size: $display3-size;
    }
}

.display-4{
    font-size: $display4-size * 0.85;

    @include media-breakpoint-up(md){
        font-size: $display4-size * 0.9;
    }
    @include media-breakpoint-up(lg){
        font-size: $display4-size * 0.95;
    }
    @include media-breakpoint-up(xl){
        font-size: $display4-size;
    }
}

/*------------------------------ Main Containers -----------------------------------------*/
.main-container{
	margin: 0;
	padding: 0;
	min-height: 100%;
	position: relative;

    @include media-breakpoint-up(md){
	    padding-bottom: 75px;   /* Height of the footer */
	}
}

.footer-container{
	margin-top: 20px;
	bottom: 0;
	width: 100%;
	padding: 10px 0 10px 0;
	background-color: $black;
    
	@include media-breakpoint-up(md){
	    position: absolute;
        height: 80px;   /* Height of the footer */
    }
}

.navigation-container{
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(md){
        background-color: $dark;
    }

    @include media-breakpoint-up(md){
        background-color: $dark;
        border-bottom: 4px solid $primary;
    }
}

.content-container{}

.standard-fixed-container{
    max-width: 1800px;
}

.embedded-pdf-container {
    padding-bottom: 25px;

    .card-header{
        button{
            padding: 0;
            border: 0;
        }

        .pdf-filename{
            display: block;
            margin-bottom: 5px;

            @include media-breakpoint-up(md){
                display: inline;
            }
        }

        .pdf-metadata{
            display: block;

            @include media-breakpoint-up(md){
                display: inline;
                float: right;
            }
        }
    }

    .card-body{
        background-color: $light;
        overflow: auto;
        padding: 0;

        .pdf-nav{
            background-color: $dark;
            padding: 0.75rem 1.25rem;
            color: #fff;
            width: 100%;
            font-size: 0.85rem;

            .container-fluid {
                padding: 0;

                .col{
                    text-align: center;
                }

                .form-control {
                    width: 50px;
                    display: inline-block;
                    text-align: center;
                    margin-top: 1px;
                }

                button {
                    display: inline-block;
                }
            }
        }

        canvas {
            width: 0; /* Start canvas with no width */
            height: 0; /* Start canvas with no height */
            border: 1px solid $hr-border-color;
            display: block;
            margin: 0 auto;

            @include media-breakpoint-up(sm){
                margin: 1.25rem auto;
            }
        }
    }
}

.welcome-message-container{
    .modal{
        transition: opacity 0.1s linear;

        .modal-header{
            border-bottom: 0px;

            img{
                max-width: 250px;
            }
        }

        .modal-content{
            background: transparent;
            color: #fff;

            .modal-body{
                text-align: center;

                .btn{
                    margin-top: 30px;
                }

                .welcome-message{
                    font-size: 2.5rem * 0.5;

                    @include media-breakpoint-up(md){
                        font-size: 2.5rem * 0.7;
                    }
                    @include media-breakpoint-up(lg){
                        font-size: 2.5rem * 0.9;
                    }
                    @include media-breakpoint-up(xl){
                        font-size: 2.5rem;
                    }
                }
            }
        }

        .close{
            color: #fff;
            text-shadow: none;
            font-size: 2.5rem;
            opacity: 1;
        }
    }
}

/*------------------------------------- Headers -------------------------------------------*/
.main-header{
    @media print {
        background-color: #fff;
    }

    @include media-breakpoint-up(md){
        height: 100px;
    }
}

.header-left{
    float: left;

    a{
        text-decoration: none;
    }

    img{
        @include media-breakpoint-up(md){
            padding: 0;
            margin: 30px 5px 0 0;
            max-width: 250px;
        }
    }
}

.header-right{
    float: right;

    @include media-breakpoint-up(md){
        margin: 30px 0 0 0;
    }
}

/* ----------------------------------- Navigation -------------------------------------------*/
.navigation-search{
    @include media-breakpoint-up(md){
        margin-top: 25px;
    }
    /*@include media-breakpoint-up(lg){
        width: auto;
    }*/
}

/* ----------------------------------------------- Footer ------------------------------------ */
.footer{
    font-size: $font-size-base * 0.9;
    color: #fff;

    a {
        color: #fff;
        /*text-decoration: underline;*/
    }
}

/* ---------------------------------------------- Other ---------------------------------------*/
.hero{

    background-position: left bottom;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    background-size: 100%;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    display: block;
    background-image: linear-gradient(35deg, rgba(0,0,0,0.65) 50%, rgba(0,0,0, 0.10) 80%), url('/assets/images/vernon/explore/hero_768.jpg');
    margin: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #fff;

    @media(min-width: 768px){
        width: 100%;
        background-image: linear-gradient(0deg, rgba(0,0,0,0.65) 10%, rgba(0,0,0, 0.10) 90%), url('/assets/images/vernon/explore/hero_992.jpg');
        padding-top: 30px;
        height: 700px;
    }
    @media(min-width: 992px){
        padding-top: 40px;
        background-image: linear-gradient(0deg, rgba(0,0,0,0.65) 10%, rgba(0,0,0, 0.10) 85%), url('/assets/images/vernon/explore/hero_1200.jpg');
        height: 500px;
    }
    @media(min-width: 1200px){
        padding-top: 50px;
        background-image: linear-gradient(0deg, rgba(0,0,0,0.65) 10%, rgba(0,0,0, 0.10) 80%), url('/assets/images/vernon/explore/hero_1800.jpg');
        min-height: 500px;
        height:85vh;
    }
    @media(min-width: 1800px){
        background-image: linear-gradient(0deg, rgba(0,0,0,0.65) 10%, rgba(0,0,0, 0.10) 70%), url('/assets/images/vernon/explore/hero.jpg');
        padding-top: 50px;
        min-height: 490px;
        height:85vh;
    }

    h1{
        color: #fff;
    }

    small{
        display: block;
        margin: 10px 0 30px 0;
    }

    p{
        color: #fff;

        @include media-breakpoint-up(md){
            font-size: 1.2rem;
        }
        @include media-breakpoint-up(xl){
            font-size: 1.3rem;
        }
    }

    .container-fluid, .row, .col, .d-flex {
        height: 100% !important;
    }
}

.hero-home{

    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    background-size: 100%;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    display: block;
    background-image: linear-gradient(135deg, rgba(0,0,0,0.65) 50%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/home/hero_bg_768.jpg');
    margin: 0;
    padding-top: 30px;

    @media(min-width: 768px){
        width: 100%;
        background-image: linear-gradient(135deg, rgba(0,0,0,0.65) 40%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/home/hero_bg_992.jpg');
        padding-top: 30px;
        height: 500px;
    }
    @media(min-width: 992px){
        background-attachment: fixed;
        background-image: linear-gradient(135deg, rgba(0,0,0,0.65) 60%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/home/hero_bg_1200.jpg');
        padding-top: 40px;
        height: 500px;
    }
    @media(min-width: 1200px){
        background-image: linear-gradient(135deg, rgba(0,0,0,0.65) 60%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/home/hero_bg_1800.jpg');
        padding-top: 50px;
        height: 500px;
    }
    @media(min-width: 1800px){
        background-image: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/home/hero_bg.jpg');
        padding-top: 50px;
        height: 500px;
    }

    h1{
        color: #fff;
    }

    small{
        display: block;
        margin: 10px 0 30px 0;
    }

    p{
        color: #fff;
    }
}

.text-image-container{

    margin-top: 40px;

    img{
        max-width: 100%;

        @media(max-width: 767px){
            display: block;
            margin: 0 auto;
        }
    }

    .text-image{
        width: 100%;
        margin: 15px 0 0 0;

        a{
            display: block;

            &:hover{
                @media(min-width: 768px){
                    background-color: black;
                }
            }
        }

        img{
            @media(min-width: 768px){
                opacity: 0.9;
            }

            &:hover{
                @media(min-width: 768px){
                    opacity: 1;
                }
            }
        }

        h2 {

            @media(max-width: 767px){
                margin-top: -30px;
                text-align: center;
            }
            @media(min-width: 768px){
                position: absolute;
                top: 150px;
                left: -5px;
                font-size: 1.2em;
            }
            @media(min-width: 992px){
                top: 200px;
                left: 0;
                font-size: 1.6em;
            }
            @media(min-width: 1200px){
                top: 250px;
                left: 0;
                font-size: 1.8em;
            }

            span {
                color: #fff;
                padding: 5px 15px 5px 15px;
                position: relative;
                top: -100px;
                -webkit-box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);
                -moz-box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);
                box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);

                @media(min-width: 768px){
                    border-radius: 2px;
                    position: unset;
                }
            }
        }
    }
}

.page-header{
    margin-top: 30px;
    margin-bottom: 15px;
}

.ranked-tag-cloud-container{
    .ranked-tag-cloud{
        .btn{
            margin: 10px 10px 0 0;
        }
    }
}

.hero-simple{
    display: block;
    background-color: $black;
    padding: 30px 0;
    width: 100%;
    color: #fff;

    p{
        @media(min-width: 768px){
            font-size: 1.4em;
        }
    }
}

.easy-nav-container{
    display: block;
    float: left;
    width: 100%;
    margin-top: 10px;

    @media(min-width: 768px){
        margin-top: 10px;
    }

    .easy-nav{
        display: block;
        float: left;
        margin: 10px 40px 10px 0px;

        @media(max-width: 767px){
            width: 100%;
            height: auto;
        }

        @media(min-width: 768px){
            width: auto;
            height: auto;
        }

        a{
            vertical-align: middle;
            display: block;
            color: #fff;
            font-size: 1.2em;

            @media(max-width: 767px){
                text-align: left;
                width: 100%;
                height: auto;
                padding: 10px 0;
            }

            @media(min-width: 768px){
                text-align: center;
                width: auto;
                height: auto;
                padding: 10px 15px 15px 0;
            }
        }
    }
}

.color-swatch-container{
    .color-swatch{
        display: block;
        width: 60px;
        height: 60px;
        float: left;
        margin-right: 25px;
        margin-top: 20px;

        span{
            display: block;
            border-radius: 30px;
            width: 100%;
            height: 100%;
        }
    }
}

.highlights-container{
    img {
        max-width:100px;
        max-height: 100px;

        @include media-breakpoint-up(md){
            max-width: 200px;
            max-height: 200px;
        }
    }
}

.hero-tile-container {
    display: block;
    position: relative;
    background-color: $primary;

    &:hover{
        opacity: 0.7;
    }

    .hero-tile {
        width: 100%;
        height: 0;
        padding-top: 66.66%; /*Calculated based on an aspect ratio of 3:2 i.e (512/768 * 100)*/
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        display: block;
    }

    h3{
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: $primary;
        color: #fff;
        margin: 0px;
        padding: 10px;
        opacity: 0.9;

        @media(max-width: 1199px){
            font-size: 1em;
        }
        @media(min-width: 1200px){
            font-size: 1.2em;
        }
    }

}

.pagination-bottom{
    /*border-top:1px solid $hr-border-color;*/
    padding-top: 20px;
    padding-bottom: 20px;
}

.top-search-box{

    -webkit-box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;
    -moz-box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;
    box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;

    display: block !important;
    background-color: $dark;
    margin: 15px 0;
    padding: 15px 15px 30px 15px;
    color: #fff;

    .top-search-box-title{
        width: 100%;
    }

    .top-search-box-read-more{
        width: 100%;

        .top-search-box-link{
            border-bottom: 1px solid #fff;

            &:hover{
                cursor: pointer;
            }
        }
    }

    .top-search-box-content{
        display: block;
        width: 100%;
        clear: both;
    }

    img{
        float: left;
        margin: 0 15px 15px 0;

        @media(max-width: 350px){
            display: none;
        }

        @media(min-width: 351px){
            max-width: 90px;
        }

        &:hover{
            cursor: pointer;
        }
    }
}

.side-refine{
    text-align: center;
    background-color: $primary;
    padding: 7px 10px;
    width: 100%;
    color: #fff;
    font-weight: bold;
    display: block;

    @include media-breakpoint-up(lg){
        display: none;
    }

    &:hover{
        cursor: pointer;
    }
}

.side-facet{

    background-color: $light;
    padding: 0 15px 15px 15px;
    display: none;

    @include media-breakpoint-up(lg){
        display: block !important;
    }

    .side-facet-title{
        padding: 0;
        width: 100%;
        color: $black;
        font-weight: bold;
    }

    .side-facet-content{
        display: inline-block;
        width: 100%;

        .fa-check-square-o {
            color: $primary;
        }
    }

    .facet-value{
        &:before{
            content: "("
        }
        &:after{
            content: ")"
        }
    }

    a{
        color: $black;

        &:hover{
            color: $primary;
            text-decoration: none;
        }
    }

    a.active{
        color: $black;

        &:hover{
            color: $dark;
        }
    }

    ul{
        margin-bottom: 20px;
        padding: 5px 15px 10px 0;
        list-style: none;
        border-bottom: 1px solid $hr-border-color;

        li{
            margin-bottom: 5px;
            /*text-transform: capitalize;*/
        }
    }

    .side-facet-range{

        padding: 15px 0 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid $hr-border-color;

        .from{
            width: 26%;
            display: inline;
        }

        .separator{
            text-transform: capitalize;
            margin: 0 4px;
        }

        .to{
            width: 26%;
            display: inline;
        }

        .btn{
            margin-left: 5px;
            @media(min-width:1200px){
                margin-left: 0;
                margin-top: 10px;
                display: block;
            }

            @media(min-width:1350px){
                margin-left: 5px;
                margin-top: 0;
                display: inline-block;
            }
        }
    }

    .side-color-title{
        text-transform: capitalize;
    }

    .side-facet-color{

        display: block;
        float: left;
        width: 100%;

        ul{
            display: block;
            float: left;
            width: 100%;

            li{
                display: block;
                float: left;
            }

        }

        a {
            display: block;
            width: 25px;
            height: 25px;
            margin-right: 10px;
            margin-bottom: 5px;
            padding: 2px;
            border: 1px solid $hr-border-color;
            border-radius: 15px;
        }

        .side-facet-color-swatch{
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 15px;

            span{
                display: block;
                margin: 0 auto;
                width: 25px;
                height: 25px;
                border-radius: 15px;
            }
        }

        .active {
            border: 1px solid $primary;
        }

        .side-color-more{
            width: 100%;
        }
    }

    .side-remove-all-filters{
        text-transform: uppercase;
        display: block;
        color: $primary;
        font-weight: bold;

        &:before{
            content: "[ "
        }

        &:after{
            content: " ]"
        }
    }

}

.side-search{
    background-color: $light;
    padding: 15px 15px 5px 15px;
    display: none;
    margin-top: 15px;

    @include media-breakpoint-up(lg){
        display: block !important;
    }

    .side-search-title{
        padding: 7px 0;
        width: 100%;
        font-weight: bold;
        font-size: $h4-font-size;
    }
}

.sort-view-container{

    @mixin sort-view-label(){
        font-weight: bold;
    }

    display: block;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid $hr-border-color;
    padding: 15px 0 15px 0;

    .more-options-holder{

        @include sort-view-label();

        display: block;
        margin-top: 10px;

        @media(max-width: 549px){
            float: left;
            width: 100%;
        }
        @media(min-width: 550px){
            float: left;
            clear: both;
        }

        &:hover{
            cursor: pointer;
        }

        .fa-close{
            margin-left: 4px;
        }

        .fa-chevron-down{
            margin-left: 1px;
        }

    }

    .view{
        display: block;

        @include media-breakpoint-down(sm){
            float: left;
            width: 100%;
            padding-top: 15px;
        }
        @include media-breakpoint-up(md){
            float: right;
        }

        .label{

            @include sort-view-label();

            @include media-breakpoint-up(md){
                float: left;
                margin-right: 10px;
                padding-top: 7px;
            }
        }
    }

    .current-filters{
        display: block;
        clear: both;
        float: left;
        margin-top: 10px;
        width: 100%;

        .label{
            @include sort-view-label();
            float: left;
            width: 100%;
            text-transform: capitalize;
        }

        .current-filter-item{
            list-style: none;
            overflow: hidden;
            padding-left: 0;
            margin: 0;
            float: left;

            li{
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;

                @media(max-width: 500px){
                    width: 100%;
                }
                span.filter-term {
                    padding: 2px 10px 2px 10px;
                    color: #fff;
                    background: $primary;
                    border-radius: 3px 0px 0 3px;
                    text-transform: capitalize;

                    @media(max-width: 500px){
                        width: 35%;
                        overflow: hidden;
                        white-space:nowrap;
                        text-overflow: ellipsis;
                    }
                }

                span.filter-label {
                    color: $black;
                    padding: 2px 10px;
                    background: $light;
                    /*text-transform: capitalize;*/

                    @media(max-width: 500px){
                        width: 40%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space:nowrap;
                    }
                }

                a.filter-close{
                    padding: 2px 10px 2px 10px;
                    color: #fff;
                    background: $dark;
                    border-radius: 0px 3px 3px 0px;
                    text-align: center;

                    @media(max-width: 500px){
                        width: 15%;
                    }

                    &:hover {
                        background: $danger;
                        color: #fff;
                    }
                }

                a.filter-close, span.filter-term, span.filter-label {
                    text-decoration: none;
                    position: relative;
                    font-size: 0.8em;
                    display: block;
                    float: left;
                }
            }
        }
    }

    .more-options{

        width: 100%;
        float: left;
        background: $light;
        padding: 0px 15px 10px;
        border-radius: $border-radius;

        .collapse{
            display: none !important;
        }

        .add-all-shortlist{

            display: block;
            float: left;
            padding-top: 7px;

            @media(max-width: 549px){
                width: 100%;
            }

            .v-label{
                @include sort-view-label();
            }

            ul{
                padding: 0px;
                margin: 0px;
                list-style: none;
            }

            li{
                @include media-breakpoint-down(md){
                    margin-top: 10px;
                }
            }

            .shortlist-action{
                color: $black;

                &:hover{
                    color: $primary;
                    cursor: pointer;
                }
            }
        }

    }

    .sort{

        float: left;
        padding: 0;

        @media(max-width: 549px){
            width: 100%;
        }

        label{
            padding: 7px 10px 0 0;
            float: left;
            @include sort-view-label();
        }

        select{
            width: auto;
            display: inline;
            float: left;
        }

        .fa-sort-alpha-up, .fa-sort-alpha-down {
            display: block;
            float: left;
            margin-left: 10px;
            margin-top: 10px;
            font-size: 1.2em;
        }

        .hidden{
            display: none;
        }

        #sortdirection{
            color: $black;

            &:hover{
                color: $primary;
            }
        }
    }
}

.nav-pill-views {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    @media screen and (max-width: 359px){
        width:100%;
    }
    @include media-breakpoint-up(md){
        float: right;
        background-color: $light;
        border-radius: $border-radius;
    }

    li{
        float: left;

        @include media-breakpoint-down(sm){
            width:100%;
            clear: both;
            margin-top:10px;
            margin-left: 0 !important;
            border-radius: 0 !important;
        }

        &:last-child{
            float: left;

            @include media-breakpoint-down(sm){
                width:100%;
                clear: both;
                margin-left: 0 !important;
                border-radius: 0 !important;
            }

            a{
                @include media-breakpoint-up(md){
                    padding-right: 20px !important;
                }
            }
        }

        &:first-child{
            a{
                @include media-breakpoint-up(md){
                    padding-left: 20px !important;
                }
            }
        }

        a{
            border: 0;
            display: block;
            color: $dark;

            @include media-breakpoint-up(md){
                padding: 5px 10px !important;
                font-size: 1.2em;
            }

            &:hover{
                color: $primary;
            }
        }

        &.active{
            a{
                color: $primary;
            }
        }
    }
}

.object-masonry-container {

    .lightbox-sizer{
        float: left;

        @media screen and (max-width: 549px){
            width:100%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 550px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1180px){
            width:31%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1430px){
            width:23%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1820px){
            width: 300px;
            margin: 0 15px 15px 0;
        }
    }

    .lightbox-item {
        border: 1px solid #d9d9d9;
        box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
        text-align: center;
        word-wrap: break-word;
        background-color: #f9f9f9;

        @media screen and (max-width: 549px){
            width:100%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 550px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1180px){
            width:31%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1430px){
            width:23%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1820px){
            width: 300px;
            margin: 0 15px 15px 0;
        }

        a, span.no-link {
            display: block;
            margin-bottom: 10px;
        }

        .color-block{
            display: inline-block;
            width: 100%;
            margin-top: 15px;

            a {
                float: left;
                background-color: #fff;
                border: 1px solid $hr-border-color;
                border-radius: 15px;
                width: 25px;
                height: 25px;
                margin-right: 7px;
                padding: 2px;
            }

            span{
                display: block;
                border-radius: 15px;
                width: 100%;
                height: 100%;
            }
        }

        .lightbox-no-image-holder{
            width: 100%;
            height: 150px;
            overflow: hidden;
            background-color: #fff;
            background: #ddd;
            background: -moz-linear-gradient(left, rgba(253, 253, 253, 1) 0%, rgba(221, 221, 221, 0.5) 100%);
            background: -webkit-linear-gradient(left, rgba(253, 253, 253, 1) 0%, rgba(221, 221, 221, 0.5) 100%);
            background: linear-gradient(135deg, rgba(253, 253, 253, 1) 0%, rgba(221, 221, 221, 0.5) 100%);
            color: $dark;
            font-size: 0.85rem;
            padding: 15px 20px 20px 20px;
            text-align: left;
            opacity: 0.9;
            display: table;

            span{
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
        }
    }

    .lightbox-img{
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }

    .lightbox-object-desc {

        border-radius: 0 0 5px 5px;
        padding: 15px 20px 20px 20px;

        a, .no-link {
            text-align: left;
        }

        a:first-child {
            font-weight: bold;
        }
    }

    .lightbox-item-no-image{
        background-color: #f9f9f9;
        padding-top: 20px !important;
    }

    .lightbox-record-type{
        position: absolute;
        height: 0;
        width: 0;
        bottom: 0;
        right: 0;
        border-left: 50px solid transparent;
        border-bottom: 47px solid $hr-border-color;

        svg, span{
            position: absolute;
            right: 7px;
            bottom: -42px;
            color: #fff;
        }
    }
}

.person-masonry-container {

    .lightbox-sizer{
        float: left;

        @media screen and (max-width: 549px){
            width:100%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 550px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1180px){
            width:31%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1430px){
            width:23%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1820px){
            width: 300px;
            margin: 0 15px 15px 0;
        }
    }

    .lightbox-item {
        background-color: #f9f9f9;
        border: 1px solid #d9d9d9;
        box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
        margin: 0 2% 2% 0;
        text-align: center;
        word-wrap: break-word;

        @media screen and (max-width: 549px){
            width:100%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 550px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1180px){
            width:31%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1430px){
            width:23%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1820px){
            width: 300px;
            margin: 0 15px 15px 0;
        }

        a, .no-link {
            display: block;
            margin-bottom: 10px;
        }
    }

    .lightbox-img {
        display:block;
        max-width:100%;
        margin:0 auto;
    }

    .lightbox-person-desc {

        border-radius: 0px 0px 5px 5px;
        padding: 10px 20px 20px 20px;

        a, .no-link {
            text-align: left;
        }

        a:first-child {
            font-size: 1.1em;
            font-weight: bold;
        }
    }

    .lightbox-item-no-image{
        background-color: #f9f9f9;
        padding-top: 20px !important;
    }

    .lightbox-record-type{
        position: absolute;
        height: 0;
        width: 0;
        bottom: 0px;
        right: 0px;
        border-left: 50px solid transparent;
        border-bottom: 47px solid $hr-border-color;

        svg, span{
            position: absolute;
            right: 7px;
            bottom: -42px;
            color: #fff;
        }
    }
}

.venue-masonry-container {

    .lightbox-sizer{
        float: left;

        @media screen and (max-width: 549px){
            width:100%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 550px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1180px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1430px){
            width:31%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1820px){
            width: 410px;
            margin: 0 20px 20px 0;
        }
    }

    .lightbox-item {
        background-color: #f9f9f9;
        border: 1px solid $hr-border-color;
        box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
        margin: 0 2% 2% 0;
        text-align: center;
        word-wrap: break-word;

        @media screen and (max-width: 549px){
            width:100%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 550px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1180px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1430px){
            width:31%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1820px){
            width: 410px;
            margin: 0 20px 20px 0;
        }

        a, .no-link {
            display: block;
            margin-bottom: 10px;
        }

        .image {
            overflow: hidden;
            width: 100%;
            float: left;
            margin-bottom: 20px;
            max-height: 350px;

            img{
                display:block;
                width:100%;
            }
        }

        .desc {
            border-radius: 0px 0px 5px 5px;
            padding: 10px 20px 20px 20px;

            a, .no-link {
                text-align: left;
            }

            a:first-child {
                font-size: 1.1em;
                font-weight: bold;
            }
        }

        .no-image{
            background-color:  #f9f9f9;
            padding-top: 20px !important;
        }

        .record-type{
            position: absolute;
            height: 0;
            width: 0;
            bottom: 0px;
            right: 0px;
            border-left: 50px solid transparent;
            border-bottom: 47px solid $hr-border-color;

            svg, span{
                position: absolute;
                right: 7px;
                bottom: -42px;
                color: #fff;
            }
        }
    }
}

.topic-masonry-container {

    .lightbox-sizer{
        float: left;

        @media screen and (max-width: 549px){
            width:100%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 550px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1180px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1430px){
            width:31%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1820px){
            width: 410px;
            margin: 0 20px 20px 0;
        }
    }

    .lightbox-item {
        background-color: #f9f9f9;
        border: 1px solid $hr-border-color;
        box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
        margin: 0 2% 2% 0;
        text-align: center;
        word-wrap: break-word;

        @media screen and (max-width: 549px){
            width:100%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 550px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1180px){
            width:48%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1430px){
            width:31%;
            margin: 0 2% 2% 0;
        }

        @media screen and (min-width: 1820px){
            width: 410px;
            margin: 0 20px 20px 0;
        }

        a, .no-link {
            display: block;
            margin-bottom: 10px;
        }

        .image {
            overflow: hidden;
            width: 100%;
            float: left;
            margin-bottom: 20px;
            max-height: 350px;

            img{
                display:block;
                width:100%;
            }
        }

        .desc {
            border-radius: 0px 0px 5px 5px;
            padding: 10px 20px 20px 20px;

            a, .no-link {
                text-align: left;
            }

            a:first-child {
                font-size: 1.1em;
                font-weight: bold;
            }
        }

        .no-image{
            background-color:  #f9f9f9;
            padding-top: 20px !important;
        }

        .record-type{
            position: absolute;
            height: 0;
            width: 0;
            bottom: 0px;
            right: 0px;
            border-left: 50px solid transparent;
            border-bottom: 47px solid $hr-border-color;

            svg, span{
                position: absolute;
                right: 7px;
                bottom: -42px;
                color: #fff;
            }
        }

        .lbl{
            float: left;
            font-weight: bold;
            display: inline-block;
            width: 100%;
            text-align: left;
            margin-top: 10px;
        }

        .tag{
            display: inline-block;
            border: 1px solid $link-color;
            padding: 7px 15px;
            text-decoration: none;
            margin-top: 5px;
            margin-right: 5px;
            margin-bottom: 20px;
            float: left;

            &:hover{
                text-decoration: none;
                background-color: $link-color;
                color: #fff;
            }
        }
    }
}


.lazy-loading-button{
    margin-left: auto !important;
    margin-right: auto !important;
    display: none;
    margin-top: 20px;
}

.lazy-loading{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: none;
    margin-left: auto;
    margin-right: auto;
    height: unset !important;
}

.lazy-loading-lightbox{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: unset !important;
}

.shortListNavigationButtonRow{


    .btn{

        border: 0;
        background-color: transparent;
        margin-top: 5px;

        svg, .fa-star{
            font-size: 1.6rem;
        }

        .fa-star{
            color: $primary;
        }

        &:hover, &:focus, &:active{
            background-color: transparent;
            color: $black;
        }
    }

    .shortListNavigationButtonDetail {
        svg, .fa-star {
            font-size: 2rem;
        }
        .btn{
            padding: 0 !important;
            margin-top: 0 !important;
        }
    }
}

.summary-subheading{
    color: $black;
    font-size: 1em;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 15px;
}

.facet-modal{

    .modal-loader {
        display: block;
        margin: 30px auto !important;
        width: 65px;
    }

    .modal-header {

        background-color: $primary;
        border-bottom: 1px solid $hr-border-color;

        h2{
            color: #fff;
        }
    }

    .facet-more-content{

        ul.facet-more-terms {
            list-style: none;
            padding: 0;

            @include media-breakpoint-up(lg){
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
            }

            li{
                margin-bottom: 5px;
            }

            a{
                color: $black;

                &:hover{
                    color: $primary;
                    text-decoration: none;
                }
            }

            .fa-check-square-o{
                color: $primary;
            }
        }

        .facet-value{
            &:before{
                content: "("
            }
            &:after{
                content: ")"
            }
        }

        .facet-no-records{
            text-align: center;
            font-weight: bold;
            font-style: italic;
        }
    }

    .facet-pagination-item{
        cursor: pointer;
    }

    .more-facet-block{
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 2px;
    }
}

.label-img {
    max-width: 100%;
    width: auto;
    height: auto;

    @media(min-width: 1800px){
        max-width: 300px;
        max-height: 300px;
    }
}

.venue-label-record{

    background: rgb(242,242,242);
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: .5rem;

    @include media-breakpoint-up(sm){
        border-bottom: 1px solid #fff;
        padding-bottom: 0;
    }

    &.gradient-left{
        background: linear-gradient(135deg, rgba(242,242,242, 1) 10%, rgba(255,255,255,0.8) 100%);
    }

    &.gradient-right{
        background: linear-gradient(135deg, rgba(255,255,255,0.8) 10%, rgba(242,242,242,1) 100%);
    }

    .col-6{
        max-width: 800px;
    }

    .image{
        float: left;
        width: 100%;
        max-height: 600px;
        overflow: hidden;

        img{
            float: left;
            max-width: 100%;
        }
    }

    .desc {
        a, span{
            display: inline-block;
            margin-bottom: .5rem;

            @include media-breakpoint-up(md){
                font-size: 1.1em;
            }

            @include media-breakpoint-up(xl){
                font-size: 1.4em;
            }

            &:first-child{
                font-weight: bold;
                margin-bottom: 1rem;

                @include media-breakpoint-up(md){
                    font-size: $h6-font-size;
                }

                @include media-breakpoint-up(xl){
                    font-size: $h2-font-size;
                }
            }
        }

        .lbl{
            margin-top: 10px;
            font-weight: bold;
            color: $dark;
        }
    }

    .no-image{
        float:left;
        display:block;
        width: 100%;
        height:300px;

        @include media-breakpoint-up(sm){
            height:400px;
            font-size: 3.5em;
            overflow: hidden;
            padding: .2em;
            color: #fff;
        }

        @include media-breakpoint-up(md){
            font-size: 4.275em;
        }

        @include media-breakpoint-up(lg){
            font-size: 5.275em;
        }

        @include media-breakpoint-up(xl){
            height:400px;
        }
    }
}

.topic-label-view-dynamic-container{

    .row:last-child{
        .topic-label-record{
            border-bottom: 0 !important;
        }
    }

    .topic-label-record{

        border-bottom: 1px solid $hr-border-color;
        padding-bottom: 45px;
        overflow: auto; /*clear fix*/

        .desc {
            display: block;
            margin-top: 15px;
            float: left;

            @include media-breakpoint-up(md){
                width: 70%;
            }

            a {
                display: block;

                &:first-child{
                    font-size: $h2-font-size;
                    margin-bottom: 15px;
                }
            }

            .lbl{
                font-weight: bold;
                margin-bottom: 5px;
            }

            .tag{
                display: inline-block;
                border: 1px solid $link-color;
                padding: 7px 15px;
                text-decoration: none;
                margin-right: 5px;
                margin-bottom: 10px;

                &:hover{
                    text-decoration: none;
                    background-color: $link-color;
                    color: #fff;
                }
            }

            .read-more{
                margin-top: 15px;
            }
        }

        .image{

            float: left;
            margin-bottom: 15px;
            padding-right: 35px;
            width: 100%;

            @include media-breakpoint-up(md){
                width: 30%;
                max-width: 400px;
            }

            a{
                position: relative;
                width: 100%;
                height: 100%;
                display: block;

                img{
                    max-width: 100%;
                    margin: 0 auto;
                    display: block;
                }
            }

            .no-image{
                float:left;
                display:block;
                width: 100%;
                background: linear-gradient(135deg, $light 0%, #fff 100%, transparent 65%);

                @include media-breakpoint-up(sm){
                    height:350px;
                    font-size: 3.5em;
                    overflow: hidden;
                    padding: .2em;
                    color: #fff;
                }

                @include media-breakpoint-up(md){
                    font-size: 3.5em;
                }

                @include media-breakpoint-up(lg){
                    font-size: 3.75em;
                }

                @include media-breakpoint-up(xl){
                    font-size: 4.2em;
                }
            }
        }
    }
}

.nav-tabs {
    border-bottom: 1px solid $nav-tabs-link-active-border-color;

    .nav-item {

        @include media-breakpoint-down(xs){
            width: 100%;
        }

        .nav-link{
            @include media-breakpoint-down(xs){
                border: 1px solid $hr-border-color;
            }
            @include media-breakpoint-up(sm){
                border-width: 1px;
                border-color: $nav-tabs-border-color $nav-tabs-border-color transparent $nav-tabs-border-color;
            }
        }

        .nav-link.active{
            @include media-breakpoint-down(xs){
                background-color: $primary;
                color: #fff;
            }
            @include media-breakpoint-up(sm){
                border-color: $nav-tabs-link-active-border-color $nav-tabs-link-active-border-color transparent $nav-tabs-link-active-border-color;
            }
        }
    }
}

.detail-hero-container {
    width: 100%;
    background-color: $light;
    margin: 15px 0 20px 0;

    @include media-breakpoint-up(md){
        display: flex !important;
        margin: 20px 0 20px 0;
    }

    @include media-breakpoint-up(xl){
        margin: 20px 0 50px 0;
    }

    .detail-hero-image{
        max-width: 100%;
        flex-shrink:0;

        @include media-breakpoint-up(md){
            max-width: 40%;
        }
        @include media-breakpoint-up(lg){
            max-width: 50%;
        }
    }

    .detail-hero-text{
        padding: 15px;
        font-size: 1.2em;
        overflow-x: auto;

        @include media-breakpoint-up(lg){
            padding: 15px 25px;
        }
        @include media-breakpoint-up(xl){
            padding: 30px 50px;
            font-size: 1.3em;
        }

        h1{
            margin-bottom: 25px;
        }

        .btn{
            margin-top: 25px;
        }
    }
}

.comments-container{

    padding-bottom: 2rem;

    .no-comment-holder{
        /*font-style: italic;*/
        font-size: 1.1rem;
        text-align: center;
        max-width: none;
        margin-bottom: 0;
    }

    .comment-quote{

        p.text {
            font-style: italic;
            font-size: 1.1rem;
        }

        p.attr{
            font-size: 0.9rem;

            .by span{
                text-transform: capitalize;
            }
        }
    }

    .btn{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .comments-sort-container{
        width: 100%;

        @include media-breakpoint-up(sm){
            float: right;
            text-align: right;
        }

        select{
            display: inline-block;
            width: 80%;
            margin-left: 7px;
        }
    }


}

.recent-comments-container{

    .comment-recent{

        padding: 5px 0;

        @include media-breakpoint-up(lg){
            margin-right: 40px;
        }

        p.attr{
            font-size: 0.9rem;

            .by{
                text-transform: capitalize;
            }
        }

        p.text{
            font-style: italic;
        }
    }
}

.related-highlight-tag-container{
    padding-bottom: 10px;

    .multiple{
        padding-bottom: 10px;
        /*border-bottom: 1px solid $hr-border-color;*/

        &:last-child{
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .related-highlight-tag{
        display: block;
        width: 100%;
        margin-bottom: 10px;

        h5{
            display: inline-block;
            margin-top: 0;
            margin-bottom: 5px;
            color: $link-color;
        }

        img {
            max-width: 50px;
            max-height: 50px;
            text-align: left;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
}

.opac-image-grid {
    display:block;

    .o-sizer {
        background-color: transparent !important;
        border: 0 solid #d9d9d9 !important;
        box-shadow: none !important;
    }

    .o-sizer,
    .o-item{
        margin: 0 15px 15px 0;
        text-align: center;
        word-wrap: break-word;
        background-color: #f9f9f9;
        border: 1px solid #d9d9d9;
        box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;

        @media screen and (max-width: 549px){
            width:100%;
        }

        @media screen and (min-width: 550px){
            width:47%;
        }

        @media screen and (min-width: 768px){
            width:31%;
        }

        @media screen and (min-width: 992px){
            width:31%;
        }

        @media screen and (min-width: 1200px){
            width:23%;
        }

        @media screen and (min-width: 1550px){
            width:18%;
        }

        a{
            display: block;
        }

        .o-image{
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }

        .o-desc{
            width: 100%;
            display: block;
            padding: 10px;

            @media(max-width: 549px){
                text-align: center;
            }
            @media(min-width: 550px){
                text-align: left;
            }
        }

        .o-desc-no-image {
            margin: 10px;
        }

        .o-title {
            font-weight: bold;
            display: block;
            padding: 5px;
        }

        .o-info, {
            display: block;
            padding: 5px;
        }

        .o-full-details {
            font-weight: bold;
            display: block;
            padding: 5px;
        }

        .fa-5x {
            padding: 20px 0 20px 10px;
            width: 100%;
            background-color: #fff;
        }
    }
}

.opac-image-grid-large{
    .o-sizer,
    .o-item{
        @media screen and (max-width: 549px){
            width:100%;
        }

        @media screen and (min-width: 550px){
            width:47%;
        }

        @media screen and (min-width: 768px){
            width:31%;
        }

        @media screen and (min-width: 992px){
            width:31%;
        }

        @media screen and (min-width: 1200px){
            width:23%;
        }

        @media screen and (min-width: 1550px){
            width:23%;
        }
    }
}

.bdv-container{
    display: block;
    float: left;

    .bdv{
        display: block;
        width: 45px;
        height: 45px;
        margin: 0;
        overflow: hidden;
        float: left;

        &:hover{
            cursor: pointer;
        }

        img{
            &.bdv-l{
                height: 100%;
            }
    
            &.bdv-p {
                width: 100%;
            }
        }

        .birds-eye-view-detail{
            display: none;
        }
    }

    .bdv-phc{
        div {
            margin: 0;
            float: left;
    
            @media screen and (min-width: 768px){
                display: block;
            }
        }
    }
}

.bdv-modal{
    img{
        float: left;
        max-width: 100%;
        margin-bottom: 10px;

        @include media-breakpoint-up(lg){
            margin-right: 3%;
            margin-bottom: 0px;
            max-width: 57%;
        }
    }

    .bdv-detail{
        float: left;
        width: 100%;
        
        @include media-breakpoint-up(lg){
            display: block;
            width: 40%;
        }

        .bdv-label{
            display: block;
            font-weight: bold;

        }
        .bdv-data{
            display: block;
            margin-bottom: 10px;
        }

        .color-block{
            display: inline-block;
            width: 100%;
            margin-top: 5px;
            margin-bottom: 5px;

            a {
                float: left;
                background-color: #fff;
                border: 1px solid $hr-border-color;
                border-radius: 15px;
                width: 25px;
                height: 25px;
                margin-right: 7px;
                padding: 2px;
            }

            span{
                display: block;
                border-radius: 15px;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.bdv-loader{
    position: fixed;
    top:0;
    right:0;
    width: 150px;
    background-color: $light;
    text-align: center;
    padding: 5px;
    z-index: 9999;
    display: none;
    opacity: 0.8;

    span {
        animation: blinker 1s linear infinite;
        padding: 0 15px;
        font-weight: bold;
    }
}

.topic-detail-container{
    margin-bottom: 25px;

    .detail-field-content-left{

        @include media-breakpoint-up(md){
            border-top: 0;
            border-right: 1px solid $hr-border-color;
            padding-right: 2rem;
        }

        .detail-row{
            margin-bottom: 20px;
        }

        .detail-label{
            margin-bottom: 5px;
        }
    }

    .detail-field-content-right{
        padding: 2rem 0;
    }

    .topic-markdown{
        h1{
            font-size: $h3-font-size;
        }

        h2{
            font-size: $h4-font-size;
        }

        h3{
            font-size: $h5-font-size;
        }

        h4,h5,h6{
            font-size: $h6-font-size;
        }

        hr{
            display: none;
        }
    }

    .disable-links{
        a{
            color: currentColor;
            display: inline-block;
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
            text-decoration: none;
        }
    }

    .topic-share{
        h3{
            margin-top: 25px;
            margin-bottom: 15px;
        }
    }
}

.object-detail-container{

    .color-block{
        display: inline-block;
        width: 100%;
        margin-top: 5px;

        a {
            float: left;
            background-color: #fff;
            border: 1px solid $hr-border-color;
            border-radius: 25px;
            width: 50px;
            height: 50px;
            margin-right: 7px;
            padding: 3px;
        }

        span{
            display: block;
            border-radius: 25px;
            width: 100%;
            height: 100%;
        }
    }

    .detail-field-content-left{
        .detail-row{
            .v-detail-text-brief_desc{
                width: 100% !important;
                font-size: 1.3em;
                color: $dark;
                margin-bottom: 2rem;
            }

            .detail-label{
                width: 100%;
                display: block;

                @include media-breakpoint-up(xl){
                    display: inline-block;
                    vertical-align: top;
                    width: 35%;
                    max-width: 300px;
                    padding-right: 15px;
                }
            }

            .detail-value {
                width: 100%;
                display: block;
                overflow-wrap: break-word;

                @include media-breakpoint-up(xl){
                    display: inline-block;
                    width: 64%;
                }
            }
        }
    }

    .detail-field-content-right{
        border-top: 1px solid $hr-border-color;
        padding: 2rem 0;

        @include media-breakpoint-up(md){
            border-top: 0;
            border-left: 1px solid $hr-border-color;
            padding: 2rem;
        }
    }

    .detail-hero-no-image{
        width: 100%;
        background-color: $dark;
        margin: 15px 0 20px 0;
        padding: 15px;
        color: #fff;

        @include media-breakpoint-up(lg){
            padding: 15px 25px;
        }
        @include media-breakpoint-up(xl){
            padding: 15px 50px;
        }
    }
}

.object-label-view-dynamic-container{

    .color-block{
        display: inline-block;
        width: 100%;

        a {
            float: left;
            background-color: #fff;
            border: 1px solid $hr-border-color;
            border-radius: 15px;
            width: 25px;
            height: 25px;
            margin-right: 7px;
            padding: 2px;
        }

        span{
            display: block;
            border-radius: 15px;
            width: 100%;
            height: 100%;
        }
    }

    .label-no-image-holder{
        width: 100%;
        height: 150px;
        overflow: hidden;
        background-color: #fff;
        color: $dark;
        font-size: 0.85rem;
        padding: 15px 20px 20px 20px;
        text-align: left;
        opacity: 0.9;
    }

}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}

#addCommentsCollapseAnchor{
    font-size: 1.1rem;
}

.birds-eye-hero-container {
    display: block;
    position: relative;
    background-color: $primary;
    text-decoration: none;

    &:hover{
        opacity: 0.7;
        text-decoration: none;
    }

    .birds-eye-hero-image {

        background-position: center;
        background-repeat: no-repeat;

        -webkit-background-size: 100%;
        -moz-background-size: 100%;
        background-size: 100%;

        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;

        display: block;
        background-image: linear-gradient(135deg, rgba(0,0,0,0.80) 0%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/explore/be_768.jpg');
        margin: 0;
        height: 380px;
        margin-top: 20px;

        @media(min-width: 768px){
            width: 100%;
            background-image: linear-gradient(135deg, rgba(0,0,0,0.80) 0%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/explore/be_992.jpg');
            margin-top: 20px;
        }
        @media(min-width: 992px){
            background-image: linear-gradient(135deg, rgba(0,0,0,0.80) 0%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/explore/be_1200.jpg');
        }
        @media(min-width: 1200px){
            background-image: linear-gradient(135deg, rgba(0,0,0,0.80) 0%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/explore/be_1800.jpg');
            margin-top: 25px;
        }
        @media(min-width: 1800px){
            background-image: linear-gradient(135deg, rgba(0,0,0,0.80) 0%, rgba(255,255,255, 0) 100%), url('/assets/images/vernon/explore/be.jpg');
        }
    }

    h3{
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: $primary;
        color: #fff;
        margin: 0px;
        padding: 10px;
        opacity: 0.9;

        @media(max-width: 1199px){
            font-size: 1em;
        }
        @media(min-width: 1200px){
            font-size: 1.2em;
        }
    }
}

.explore-total-count {
    color: #fff;
    text-decoration: underline;

    &:hover{
        color: #fff;
        text-decoration: underline;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.g-recaptcha-info{
    font-size: 0.75rem;
    color: $gray-600;
    margin-top: 15px;

    img{
        max-height: 25px;
    }
}

.show-password-strength{
    margin-bottom: 15px;
    display: block;
    float: left;
    height: 20px;

    .strength-indicators{
        height: 12px;
        width: 30px;
        margin-top: 4px;
        margin-right: 3px;
        border: 2px solid $light;
        display: block;
        float: left;
    }

    .strength-desc{
        color: gray;
        display: block;
        float: left;
        margin-left: 10px;
        font-size: 0.85rem;
        font-weight: bold;
    }
}

#map {
    /* configure the size of the map */
    width: 100%;
    height: 400px;
}