/*Bootstrap default overrides*/

/*Colours*/
$black: #131822;
$primary: #0a8055; /*Green*/
$secondary: #545454; /*Green*/
$success: #cfc4c4; /*Light Brown*/
$info: #4a98b6; /*Blue*/
$warning: #ffbf47; /*Dark yellow*/
$danger: #bb4430; /*Red*/
$light: #f9f9f9; /*Light gray*/
$dark: #3d3d3d; /*Dark gray*/

/*Extra gray tones*/
$gray-200: #e9ecef;
$gray-300: #dee2e6;

/*Fonts*/
$font-family-base: "Open Sans", sans-serif;

/*Headings*/
$display1-size: 3.5rem;
$display2-size: 3.0rem;
$display3-size: 2.5rem;
$display4-size: 2.0rem;

/*Navigation*/
$navbar-dark-color: #fff;
$navbar-dark-toggler-border-color: #fff;

$nav-tabs-border-color: $gray-200;
$nav-tabs-link-active-border-color: $gray-300;

$carousel-control-color: #fff;
$carousel-control-opacity: .6;
$carousel-control-icon-width: 30px !default;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fad' data-icon='chevron-circle-left' class='svg-inline--fa fa-chevron-circle-left fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><g class='fa-group'><path class='fa-secondary' fill='currentColor' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm72.5 349.6a23.9 23.9 0 0 1 0 33.9l-17 17a23.9 23.9 0 0 1-33.9 0L142.1 273a24 24 0 0 1 0-34l135.5-135.5a23.9 23.9 0 0 1 33.9 0l17 17a23.9 23.9 0 0 1 0 33.9L226.9 256z' opacity='0.4'></path><path class='fa-primary' fill='#{$carousel-control-color}' d='M142.1 239l135.5-135.5a23.9 23.9 0 0 1 33.9 0l17 17a23.9 23.9 0 0 1 0 33.9L226.9 256l101.6 101.6a23.9 23.9 0 0 1 0 33.9l-17 17a23.9 23.9 0 0 1-33.9 0L142.1 273a24 24 0 0 1 0-34z'></path></g></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fad' data-icon='chevron-circle-right' class='svg-inline--fa fa-chevron-circle-right fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><g class='fa-group'><path class='fa-secondary' fill='currentColor' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm113.9 265L234.4 408.5a23.9 23.9 0 0 1-33.9 0l-17-17a23.9 23.9 0 0 1 0-33.9L285.1 256 183.5 154.4a23.9 23.9 0 0 1 0-33.9l17-17a23.9 23.9 0 0 1 33.9 0L369.9 239a24 24 0 0 1 0 34z' opacity='0.4'></path><path class='fa-primary' fill='#{$carousel-control-color}' d='M369.9 273L234.4 408.5a23.9 23.9 0 0 1-33.9 0l-17-17a23.9 23.9 0 0 1 0-33.9L285.1 256 183.5 154.4a23.9 23.9 0 0 1 0-33.9l17-17a23.9 23.9 0 0 1 33.9 0L369.9 239a24 24 0 0 1 0 34z'></path></g></svg>");


